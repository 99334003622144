import "./Photography.css";
import Header from "../../components/Header/Header";

import { Grid } from "@mui/material";

import { useEffect, useState } from "react";
import axios from "axios";
import RachelLogo from "../../components/RachelLogo/RachelLogo";

export function Photography() {
	// categories

	// state;
	const [photographs, setPhotographs] = useState([]);

	// useeffect
	useEffect(() => {
		// fetch images
		axios
			.get(`${process.env.REACT_APP_API_URL}/categories/photography`)
			.then((data) => {
				console.log(data.data);
				setPhotographs(data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	return (
		<div className="App">
			<Header />
			{photographs.length > 0 ? (
				<div className="gallery-section">
					<div className="section-header"> Photography </div>
					<Grid
						container
						sx={{
							// square
							height: "100%",
							width: "100%",
							// center
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{photographs.map((photograph, index) => (
							<Grid
								key={index}
								item
								lg={3}
								md={6}
								sm={12}
								sx={{
									// square
									height: "30vh",
									width: "100%",
									margin: "3rem 3rem",
								}}
							>
								<a
									className="gallery-item"
									href={"/photography/" + photograph.name}
								>
									<img
										src={
											`${process.env.REACT_APP_API_URL}/images/photography/` +
											photograph.name +
											"/" +
											photograph.image
										}
										alt="img"
										className="gallery-img"
									/>
									<h4>
										{photograph.name.replace(/\w\S*/g, (txt) => {
											return (
												txt.charAt(0).toUpperCase() +
												txt.substr(1).toLowerCase()
											);
										})}
									</h4>
								</a>
							</Grid>
						))}
					</Grid>
				</div>
			) : (
				<RachelLogo />
			)}
		</div>
	);
}

export default Photography;
