import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home/Home";
import Photography from "./Pages/Photography/Photography";
import Praises from "./Pages/Praises/Praises";
import Graphics from "./Pages/Graphics/Graphics";
import NotFound from "./Pages/NotFound/NotFound";
import Galleries from "./Pages/Galleries/Galleries";
// useeffect
import { useEffect, useState } from "react";
import axios from "axios";

// router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GraphicGallery from "./Pages/GraphicGallery/GraphicGallery";

// components

function App() {
	return (
		// router for the app
		<Router>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/graphics" element={<Graphics />} />
				<Route path="/:category/:type" element={<Galleries />} />
				{/* <Route path="/graphics/:type" element={<GraphicGallery />} /> */}

				<Route path="/photography" element={<Photography />} />
				{/* /photography/:type */}
				<Route path="/praises" element={<Praises />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
}

export default App;
