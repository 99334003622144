import "./Images.css";
// useState
import { useState } from "react";

// material ui modal backdrop fade
import {
	Box,
	Fade,
	IconButton,
	makeStyles,
	Modal,
	Paper,
	useThemeProps,
} from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const Images = ({ index, category, type, image, style }) => {
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// const useStyles = makeStyles((theme) => ({
	// 	modal: {
	// 		display: "flex",
	// 		alignItems: "center",
	// 		justifyContent: "center",
	// 	},
	// }));

	// const classes = useStyles();

	return (
		<Box
			key={index}
			sx={{
				// max height
				// width: "100%",
				position: "relative",
				overflow: "hidden",
				display: style?.display && style,
				width: style?.width && style,
			}}
		>
			<img
				src={
					`${process.env.REACT_APP_API_URL}/images/` +
					category.toLowerCase() +
					"/" +
					type +
					"/" +
					image
				}
				alt="img"
				style={{
					width: "100%",
					// height: "100%",
					objectFit: "cover",
					// maxWidth: "30vw",
				}}
				onClick={handleOpen}
			/>

			<Modal
				// className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				// BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Fade in={open}>
					<Paper
						className="modal"
						sx={{
							position: "relative",
						}}
					>
						<IconButton
							onClick={handleClose}
							sx={{
								position: "absolute",
								top: "1rem",
								right: "1rem",
								color: "white",
								zIndex: 1,
								backgroundColor: "rgba(0, 0, 0, 0.5)",
							}}
						>
							X
						</IconButton>
						<LazyLoadImage
							src={
								`${process.env.REACT_APP_API_URL}/images/` +
								category +
								"/" +
								type +
								"/" +
								image
							}
							alt="img"
							style={{
								width: "100%",
								// height: "100vh",
								maxHeight: "100vh",
								maxWidth: "100vw",
								// objectFit: "cover",
							}}
						/>
					</Paper>
				</Fade>
			</Modal>
		</Box>
	);
};

export default Images;
