import Header from "../../components/Header/Header";
import "./Praises.css";
// import fs
import axios from "axios";
import Praise from "./components/Praise";

import { Button, Divider, Grid, Pagination } from "@mui/material";
import { Box } from "@mui/system";
import { Masonry, MasonryItem } from "@mui/lab";

import { useEffect, useState } from "react";
import RachelLogo from "../../components/RachelLogo/RachelLogo";

export const Praises = () => {
	// useeffect
	const [praises, setPraises] = useState([]);
	const [page, setPage] = useState(1);

	// useeffect
	useEffect(() => {
		// fetch images
		axios
			.get(`${process.env.REACT_APP_API_URL}/praises?page=${page}`)
			.then((data) => {
				console.log(data.data.data);
				setPraises(data.data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [page]);

	return (
		<>
			<div className="section-header"> Praises </div>
			<Divider
				sx={{
					width: "5%",
					margin: "auto",
					marginBottom: "3rem",
				}}
			/>

			<Masonry
				columns={{ xs: 1, sm: 2, md: 2, lg: 4 }}
				spacing={{
					xs: 1,
					sm: 1,
					md: 2,
					lg: 3,
				}}
				sx={{}}
			>
				{praises.length > 0 ? (
					praises.map((praise, index) => (
						<Praise
							key={index}
							name={praise.name}
							date={praise.date}
							message={praise.message}
						/>
					))
				) : (
					<RachelLogo />
				)}
			</Masonry>
		</>
	);
};

export default Praises;
