import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Box } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Header = () => {
	const navigate = useNavigate();

	const scrollToSection = (section) => {
		// if not on homepage go to homepage
		if (window.location.pathname !== "/") {
			window.location.href = "/";
		}

		const element = document.querySelector(section);
		window.scroll({
			behavior: "smooth",
			left: 0,
			top: element.offsetTop,
		});
	};

	window.onload = function () {
		document.querySelector(".nav").style.backgroundColor = "#282c34";
		document.querySelector(".logo-text").style.color = "#fff";
		document.querySelector(".logo-subtext").style.color = "#fff";
	};

	const [anchorElNav, setAnchorElNav] = useState(null);
	const [anchorElUser, setAnchorElUser] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	window.onscroll = function () {
		// and width is more than 1200

		if (window.location.pathname === "/" && window.innerWidth > 1200) {
			if (window.scrollY === 0) {
				document.querySelector(".nav").style.backgroundColor = "transparent";
				document.querySelector(".logo-text").style.color = "#ffffff00";
				document.querySelector(".logo-subtext").style.color = "#ffffff00";
			} else {
				document.querySelector(".nav").style.backgroundColor = "#282c34";
				document.querySelector(".logo-text").style.color = "#fff";
				document.querySelector(".logo-subtext").style.color = "#fff";
			}
		} else {
			document.querySelector(".nav").style.backgroundColor = "#282c34";
			document.querySelector(".logo-text").style.color = "#fff";
			document.querySelector(".logo-subtext").style.color = "#fff";
		}
	};

	window.onload = function () {
		if (window.location.pathname !== "/" && window.innerWidth > 1200) {
			document.querySelector(".nav").style.backgroundColor = "#282c34";
			document.querySelector(".logo-text").style.color = "#fff";
			document.querySelector(".logo-subtext").style.color = "#fff";
		}
	};

	const handleClick = (section) => {
		navigate(`/?section=${section}`);
	};

	return (
		<>
			<div className="nav">
				<div className="logo">
					<p className="logo-text"> Rachel Brooks </p>
					<p className="logo-subtext"> Creatives </p>
				</div>
				<div className="nav-links">
					<a className="nav-link" href="/">
						{" "}
						Home{" "}
					</a>
					<a className="nav-link" href="/graphics">
						{" "}
						Graphics{" "}
					</a>
					<a className="nav-link" href="/photography">
						{" "}
						Photography{" "}
					</a>
					<a
						className="nav-link"
						href="/#praises"
						onClick={(e) => {
							e.preventDefault();

							// if not on homepage
							if (window.location.pathname !== "/") {
								handleClick("praises");
							}

							scrollToSection("#praises");
						}}
					>
						{" "}
						Praises{" "}
					</a>
					<a
						className="nav-link"
						href="/#about"
						onClick={(e) => {
							e.preventDefault();

							// if not on homepage
							if (window.location.pathname !== "/") {
								handleClick("about");
							}

							scrollToSection("#about");
						}}
					>
						{" "}
						About{" "}
					</a>
					<a
						className="nav-link"
						href="#contact"
						onClick={(e) => {
							e.preventDefault();
							if (window.location.pathname !== "/") {
								handleClick("contact");
							}
							scrollToSection("#contact");
						}}
					>
						{" "}
						Contact{" "}
					</a>
				</div>
			</div>
			<div className="nav-mobile">
				<div className="logo">
					<p className="logo-text"> Rachel Brooks </p>
					<p className="logo-subtext"> Creatives </p>
				</div>
				<Box>
					<IconButton
						size="large"
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleOpenNavMenu}
						color="inherit"
					>
						<MenuIcon />
					</IconButton>
					<Menu
						id="menu"
						anchorOrigin={{
							vertical: "top",
							horizontal: "left",
						}}
						anchorEl={anchorElNav}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(anchorElNav)}
						onClose={handleCloseNavMenu}
						sx={{
							display: { xs: "block", md: "none" },
						}}
					>
						<MenuItem
							onClick={(e) => {
								e.preventDefault();
								scrollToSection("#home");
							}}
						>
							Home
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								// go to /photogrtaphy
								window.location.href = "/graphics";
							}}
						>
							Graphics
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								// go to /photogrtaphy
								window.location.href = "/photography";
							}}
						>
							Photography
						</MenuItem>
						<MenuItem
							onClick={(e) => {
								e.preventDefault();
								scrollToSection("#praises");
							}}
						>
							Praises
						</MenuItem>
						<MenuItem onClick={(e) => scrollToSection("#about")}>
							About
						</MenuItem>

						<MenuItem
							onClick={(e) => {
								e.preventDefault();
								scrollToSection("#contact");
							}}
						>
							Contact Me
						</MenuItem>
					</Menu>
				</Box>
			</div>
		</>
	);
};

export default Header;
