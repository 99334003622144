import React, { useState, useEffect } from "react";
import axios from "axios";
import "./GraphicGallery.css";
import Header from "../../components/Header/Header";
import GraphicSection from "./GraphicSection";

import { useParams } from "react-router-dom";
import Gallery from "../Galleries/Galleries";

import RachelLogo from "../../components/RachelLogo/RachelLogo";

function GraphicGallery() {
	// get the type of gallery - logos, newsletters, packaging, etc
	// get that data for each "section" or project by an api that gives us images and then descriptons
	// api - get logo page data
	// returns array of each section [images all in an array, title of section, description of section]
	// state
	const [graphicGallery, setGraphicGallery] = useState([]);
	const { type } = useParams();

	// if type == email render a differnt layout

	// delay the rendering of the gallery until the data is fetched
	const [ready, setReady] = useState(false);

	// useeffect
	useEffect(() => {
		// fetch data

		axios
			.get(`${process.env.REACT_APP_API_URL}/graphics/` + type)
			.then((data) => {
				console.log(data.data);
				setGraphicGallery(data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const goBackPage = () => {
		window.history.back();
	};

	// if (type === "Emails") {
	// 	return <Gallery type="Emails" category="Graphics" />;
	// }

	// if (type === "Product-Launches") {
	// 	return <Gallery type="Launches" category="Graphics" />;
	// }

	// if (type === "Promo-Campaign") {
	// 	return <Gallery type="Promo-Campaign" category="Graphics" />;
	// }

	return (
		<div className="App">
			<Header />
			<div className="gallery-section">
				<div className="section-header">
					{graphicGallery && graphicGallery.name ? (
						graphicGallery.name.replace("-", " ")
					) : (
						<RachelLogo />
					)}
				</div>
				<button className="back-btn" onClick={goBackPage}>
					Previous
				</button>
				{/* for each section */}
				{graphicGallery && graphicGallery.sections ? (
					graphicGallery.sections.map((section, index) => (
						<GraphicSection
							key={index}
							section={section}
							index={index}
							type={type}
							category={"Graphics"}
						/>
					))
				) : (
					<RachelLogo />
				)}
			</div>
		</div>
	);
}

export default GraphicGallery;
