import "./RachelLogo.css";

export default function RachelLogo() {
	return (
		<div className="rachel-logo">
			<p className="rachel-logo-text rachel-animation"> Rachel Brooks </p>
			<p className="rachel-logo-subtext rachel-animation"> Creatives </p>
		</div>
	);
}
