import "./PhotographyGallery.css";
import Header from "../../components/Header/Header";
// import fs
import axios from "axios";

import { Button, Fade, Grid, Modal, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { Masonry, MasonryItem } from "@mui/lab";
// useParams
import { useParams } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { useEffect, useState } from "react";

import Images from "../../components/Images/Images";
import RachelLogo from "../../components/RachelLogo/RachelLogo";

export function Gallery(props) {
	// useParams
	// let { type, category } = useParams();
	const { type, category } = props;

	// if (!type) {
	// 	type = props.type;
	// 	category = props.category;
	// }

	// uppercase first letter and first letter after space, replace - with space

	const typeFormatted = type
		.split("-")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");

	const [images, setImages] = useState([]);

	// useeffect
	useEffect(() => {
		// fetch images
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/categories/${category}/${type}/images`
			)
			.then((data) => {
				console.log(data.data);
				// randomly sort the images
				data.data.sort(() => Math.random() - 0.5);
				setImages(data.data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const [loadedImages, setLoadedImages] = useState([]);

	const handleImageLoad = (img) => {
		console.log(img);
		setLoadedImages((prevLoadedImages) => [...prevLoadedImages, img]);
	};

	const goBackPage = () => {
		window.history.back();
	};

	// lets return a different rendering based on the category and type
	return (
		<div className="App">
			<Header />
			<div className="gallery-section">
				{/* back to previous screen */}
				<div className="section-header"> {typeFormatted} </div>
				{/* make a working back button */}

				<button className="back-btn" onClick={goBackPage}>
					Previous
				</button>

				<Masonry
					columns={{ xs: 1, sm: 2, md: images.length < 5 ? images.length : 5 }}
					spacing={4}
					sx={{
						// center
						margin: "auto",
					}}
				>
					<>
						{images.length > 0 ? (
							images.map((image, index) => (
								<div key={index}>
									{loadedImages.includes(image) && (
										<Images
											key={index}
											image={image}
											category={category}
											type={type}
											style={{ width: "100%", display: "block" }}
										/>
									)}

									{!loadedImages.includes(image) && (
										<Images
											key={index}
											image={image}
											category={category}
											type={type}
											onLoad={handleImageLoad(image)}
											style={{ display: "none" }}
										/>
									)}
								</div>
							))
						) : (
							<RachelLogo />
						)}
					</>
				</Masonry>
			</div>
		</div>
	);
}

export default Gallery;
